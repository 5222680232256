import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>TEMPOROMANDIBULAR (JAW)/JOINT PROBLEMS</h2>
      <h3>What are they?</h3>
      <p>The joint between your upper and lower jaws, just in front of your ears, is called the temporomandibular-joint ("TMJ" for short). Cartilage (a gristly substance) separates the bone surfaces. The jaw joints can occasionally cause problems, which your dentist can help diagnose and treat.</p>
      <h3>What are the symptoms?</h3>
      <p>Clicking  or grating  noises  when you move your jaw.</p>
      <p>Difficulty opening  or closing your mouth.</p>
      <p>Swelling  or tenderness over the joint.</p>
      <p>Pain in the face, neck and chewing  muscles  (some  people  also have pain in the shoulders and I or back).</p>
      <p>Headaches or migraine, especially first thing in the morning.</p>
      <p>Pain in your ear, possibly with ringing  noises.</p>
      <p>Clicking  or grating  noises  are usually  down to the cartilage  jumping when you open your mouth.</p>

      <p>The problem can be addressed by:</p>
      <ul>
        <li>cutting up food into small pieces</li>
        <li>avoiding very chewy  foods</li>
        <li>avoiding very hard foods</li>
        <li>trying not to open your mouth too wide -for example, when you yawn.</li>
      </ul>
      <p>Jaw joint pain is usually the result of increased tension in the jaw muscles. Factors involved in this include:</p>
      <ul>
        <li>grinding or clenching your teeth, in your sleep or during the day</li>
        <li>habits such as biting your nails or chewing a pen.</li>
      </ul>
      <p>These are often related to fatigue-stress and anxiety. Painkillers and sedatives can relieve symptoms in the short term.</p>
      <p>Possible solutions to the underlying problem include:</p>
      <ul>
        <li>using relaxation and stress-relieving techniques</li>
        <li>giving up habits that aggravate the condition</li>
        <li>wearing a "biteguard" at night, to stop you grinding your teeth in your sleep.</li>
      </ul>
      <p>Some people may be experiencing jaw joint problems due to the way their teeth or dentures meet when they bite. This can be resolved by adjusting the position or the shape of some teeth, by replacing missing teeth with dentures or implants, or by making a new denture, as necessary.</p>
    </LightboxLayout>
  )
}

export default Page